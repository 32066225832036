import React, { Component } from 'react';
import { translate } from 'react-switch-lang';
import { Spinner } from 'reactstrap';

import AlertSystem from '../../layout/AlertSystem';
import { MyProfile, getNationality } from '../../services/Api';
import FormProcessing from '../../services/FormProcessing';
import InputFileImg from '../../layout-user/Components/InputFileImg';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            loading: false,
            message: 'Componente SignIn',
            type: 'warning',
            userData: null,
            resetForm: false,
            nationality: [],
        };
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.loadUser();
    }

    async loadUser() {
        const res = await MyProfile();
        const res2 = await getNationality();
        this.setState({
            userData: res.data,
            nationality: res2,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        const data = await FormProcessing.toObject(this.form.current);
        let validate = [];
        const response = await MyProfile(data);
        if (response.enable_validations === true) {
            validate = response;
        }
        FormProcessing.validate(this.form.current, validate);
        if (response.error) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.error,
                type: 'danger',
            });
        } else if (response.data && response.data.authorization) {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        const {
            showAlert,
            message,
            type,
            loading,
            userData,
            resetForm,
            nationality,
        } = this.state;
        const BtnSpinner = () => {
            const style = {
                top: '-2px',
                position: 'relative',
                right: '9px',
            };
            return (
                <>
                    <Spinner
                        size="sm"
                        color="white"
                        style={style}
                    />
                    Actualizandos..
                </>
            );
        };
        if (userData === null) {
            return (
                <div className="text-center pt-5">
                    <Spinner
                        size="sm"
                        color="black"
                    />
                    <br />
                    Cargando perfil..
                </div>
            );
        }
        return (
            <div className="row justify-content-md-center pt-5">
                <AlertSystem show={showAlert} type={type} message={message} />
                <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    ref={this.form}
                    className="col-md-7 bg-white px-5 py-3 rounded shadow-sm"
                >
                    <h3 className="mb-0">
                        Mi Perfil
                    </h3>
                    <p className="text-center">
                        <InputFileImg
                            name="avatar"
                            reset={resetForm}
                            srcInput={userData.avatar ? userData.avatar : null}
                            nameFile={userData.avatar ? 'Avatar de usuario' : null}
                        />
                    </p>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Nombre
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="name"
                                className="form-control form-control-sm"
                                placeholder="Nombre"
                                aria-label="name"
                                aria-describedby="name"
                                defaultValue={userData.name}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Apellido materno
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="maternal_surname"
                                className="form-control form-control-sm"
                                placeholder="Apellido materno"
                                aria-label="maternal_surname"
                                aria-describedby="maternal_surname"
                                defaultValue={userData.maternal_surname}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Apellido paterno
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="paternal_surname"
                                className="form-control form-control-sm"
                                placeholder="Apellido paterno"
                                aria-label="paternal_surname"
                                aria-describedby="paternal_surname"
                                defaultValue={userData.paternal_surname}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Rut
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="rut"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu rut"
                                aria-label="rut"
                                aria-describedby="rut"
                                defaultValue={userData.rut}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Correo
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="email"
                                name="email"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu correo"
                                aria-label="email"
                                aria-describedby="email"
                                defaultValue={userData.email}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Telefono(principal)
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="main_phone"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu telefono"
                                aria-label="main_phone"
                                aria-describedby="main_phone"
                                defaultValue={userData.main_phone}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Cumpleaños
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name=" birth_date"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu fecha de cumpleaños"
                                aria-label="birth_date"
                                aria-describedby="birth_date"
                                defaultValue={userData.birth_date}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Genero
                        </p>
                        <div className="col-sm-9 px-0">
                            <select className="custom-select custom-select-sm" name="gender">
                                <option selected>Selecciona tu genero...</option>
                                <option value="1">Mujer</option>
                                <option value="2">Hombre</option>
                                <option value="3">Otros</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Nacionalidad
                        </p>
                        <div className="col-sm-9 px-0">
                            <select className="custom-select custom-select-sm" name="nationality">

                                <option selected>Selecciona tu nacionalidad...</option>
                                {
                                    nationality.length > 0 ? nationality.map(((k, i) => {
                                        return <option value={i}>{k[1]}</option>;
                                    }), this) : <option>Cargando..</option>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row pt-3">
                        <div className="col-md-6 text-left px-0">
                            ¿Quieres cambiar la contraseña?
                            <br />
                            <a href="#cambiar-contraseña">Ingresa aquí</a>
                        </div>
                        <div className="col-md-6 text-right px-0">
                            <button
                                type="submit"
                                className="btn btn-black"
                                disabled={loading}
                            >
                                { loading ? <BtnSpinner /> : 'Actualizar' }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export default translate(EditProfile);
