
import React, { Component } from 'react';
import { Alert } from 'reactstrap';
// type: primary,secondary,success,danger,warning,info,light,dark
// const AlertSystem = (props) => {

export default class AlertSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propsState: props,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.message !== prevProps.message) {
            this.setState({
                propsState: this.props,
            });
        }
    }

    render() {
        const { propsState } = this.state;
        const t = typeof propsState.type === 'undefined' ? 'danger' : propsState.type;
        const m = typeof propsState.message === 'undefined' ? 'Si mensaje' : propsState.message;
        const s = typeof propsState.show === 'undefined' ? true : propsState.show;
        return (
            <Alert color={t} isOpen={s} className="small px-2 py-1">
                {m}
            </Alert>
        );
    }
}
