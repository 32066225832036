import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TemplatePrivate from './layout-user/TemplatePrivate';
import Home from './pages/Home';
import ListN from './pages/Notifications/ListN';
import EditProfile from './pages/Profile/EditProfile';
import EditInstitution from './pages/institution/Update';
import EditHolding from './pages/holding/Update';
import CreateUser from './pages/Users/Create';
import UserList from './pages/Users/List';
import ListNationalities from './pages/Settings/ListNationalities';
import BillsIndex from './pages/Bills/IndexBills';
import ObservedBills from './pages/Bills/ObservedBills';
import User from './pages/Users2/User';

const AppRouteUsers = () => (
    <Router>
        <TemplatePrivate>
            <Switch>
                <Route
                    exact
                    path={
                        [
                            '/admin/dashboard',
                            '/',
                        ]
                    }
                    component={Home}
                />

                <Route exact path="/admin/usuarios/:user_id" component={User} />
                <Route exact path="/institution/edit/:id" component={EditInstitution} />
                <Route exact path="/holding/edit/:id" component={EditHolding} />


                <Route exact path="/notificaciones" component={ListN} />
                <Route exact path="/servicios" component={Home} />
                <Route exact path="/usuario" component={Home} />

                <Route exact path="/usuario/crear" component={CreateUser} />
                <Route exact path="/usuario/lista" component={UserList} />

                <Route exact path="/bills" component={BillsIndex} />
                <Route exact path="/observed-bills" component={ObservedBills} />
                
                <Route exact path="/mi/perfil" component={EditProfile} />

                <Route exact path="/ajustes/nacionalidades" component={ListNationalities} />
            </Switch>
        </TemplatePrivate>
    </Router>
);
export default AppRouteUsers;
