import Session from './Session';

const BACK_URL = process.env.REACT_APP_API_URL;
class Fetch {
    constructor() {
        this.endpoint = '';
        this.headers = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: Session.getTokenUser(),
                'Token-Api': 'BACK_TOKEN',
                Language: 'es',
                'Token-User': Session.getTokenUser(),
                'Status-User': Session.getStatusUser(),
                'Type-Profile-User': Session.getTypeProfileUser(),
            },
        };
    }

    async get(endpoint, data = {}) {
        let qs = this.encodeQueryString(data);
        qs = ((qs !== '') ? (`?${qs}`) : '');
        this.endpoint = endpoint;
        this.headers.method = 'GET';
        delete this.headers.body;
        return this.send(qs);
    }

    
    async getExcel(endpoint, data = {}) {
        let qs = this.encodeQueryString(data);
        this.endpoint = endpoint;
        this.headers.method = 'GET';
        this.headers.headers['Accept'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        delete this.headers.body;
        
        const response = await fetch(`${BACK_URL}${this.endpoint}${qs}`, this.headers);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response;
    }


    async post(endpoint, data = {}) {
        this.endpoint = endpoint;
        this.headers.method = 'POST';
        this.getFromData(data);
        return this.send();
    }

    async put(endpoint, data = {}) {
        this.endpoint = endpoint;
        this.headers.method = 'PUT';
        this.getFromData(data);
        return this.send();
    }

    async patch(endpoint, data = {}) {
        this.endpoint = endpoint;
        this.headers.method = 'PATCH';
        this.getFromData(data);
        return this.send();
    }

    async delete(endpoint, data = {}) {
        this.endpoint = endpoint;
        this.headers.method = 'DELETE';
        this.headers.body = JSON.stringify(data);
        return this.send();
    }

    async send(get = '') {
        let endpoint = BACK_URL + this.endpoint;
        endpoint = this.addParamentersUrl(endpoint, get);
        const response = await fetch(endpoint, this.headers);

        const result = await response.json();
        if (response.status === 422) {
            result.enable_validations = true;
        }
        if (response.status === 500) {
            alert(
                `Toma captura y envialo al desarrollador\n\nException:${result.exception}\nError:${result.message}\nLinea:${result.line}\nFile:${result.file}`
            );
        }
        if (result.authDetails || response.status === 401) {
            Session.close();
            window.location.href = '/';
        }
        if (result) {
            return result;
        }
        return null;
    }


    addParamentersUrl(endpoint = "", get) {
        const urlParams = new URLSearchParams(window.location.search);
        if (get !== '') {
            get = get.replace("?", "");
            const getParams = new URLSearchParams(get);
            urlParams.forEach(function (value, key) {
                getParams.set(key, value)
            });
            return endpoint + '?' + getParams.toString()
        }
        return endpoint + '?' + urlParams.toString()
    }

    encodeQueryString(filters) {
        let url = '';
        Object.keys(filters).forEach((key) => {
            if (Array.isArray(filters[key])) {
                filters[key].map((item) => {
                    if (item !== null) {
                        url += `${key}=${item}&`;
                    }
                    return true;
                }, this);
            } else if (filters[key]) {
                url += `${key}=${filters[key]}&`;
            }
        }, this);
        return url.substring(0, url.length - 1);
    }

    getFromData(data) {
        let body = '';
        if (typeof data['form-data'] === 'boolean') {

        } else {
            body = JSON.stringify(data);
        }

        this.headers.body = body;
    }
}
export default new Fetch();
