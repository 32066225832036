// import  Api from './Api'
class Session {
    constructor() {
        this.state = {
            token: localStorage.getItem('token'),
            typeProfile: localStorage.getItem('type_profile'),
            userProfile: localStorage.getItem('profile'),
            statusUser: localStorage.getItem('status_user'),
            fullName: localStorage.getItem('full_name'),
            username: localStorage.getItem('username'),
            // avatar: localStorage.getItem('avatar'),
            inCharge: localStorage.getItem('inCharge'),
            myPreExcel: localStorage.getItem('my_pre_excel')
        };
    }

    open = (data) => {
        localStorage.setItem('token', data.authorization);
        localStorage.setItem('type_profile', data.profile_id);
        localStorage.setItem('profile', data.profile_id);
        localStorage.setItem('status_user', data.status);
        localStorage.setItem('username', data.username);
        localStorage.setItem('full_name', data.full_name);
        localStorage.setItem('my_pre_excel', data.my_pre_excel);
        // localStorage.setItem('avatar', data.avatar)
    }

    isAuth = () => {
        const isAuth = ('token' in localStorage);
        return isAuth;
    }

    setInCharge = (data) => {
        localStorage.setItem('inCharge', data);
    }

    getUserInstitution = () => {
        const { inCharge } = this.state;
        return inCharge;
    }

    getTokenUser = () => {
        const { token } = this.state;
        return token;
    }

    isDiffAdminUniversity = () => {
        const { userProfile } = this.state;
        return (
            userProfile !== '1'
            && userProfile !== '2'
            && userProfile !== '3'
        );
    }

    isSuperAdmin = () => {
        const { userProfile } = this.state;
        return (userProfile === '1');
    }

    isAdminUniversity = () => {
        const { userProfile } = this.state;
        return (userProfile === '2');
    }

    isInCharge = () => {
        const { typeProfile } = this.state;
        return (typeProfile === '3');
    }
    isInAssitente = () => {
        const { typeProfile } = this.state;
        return (typeProfile === '5');
    }
    isInContabilidad = () => {
        const { userProfile } = this.state;
        return (userProfile === '8');
    }

    getStatusUser = () => {
        const { statusUser } = this.state;
        return statusUser;
    }

    getTypeProfileUser = () => {
        const { typeProfile } = this.state;
        return typeProfile;
    }

    getTypeProfileStrignUser = () => {
        let profile = '';
        switch (this.state.typeProfile) {
            case '1':
                profile = 'Super Administrador';
                break;
            case '2':
                profile = 'Administrador';
                break;
            case '3':
                profile = 'Academico';
                break;
            case '4':
                profile = 'Coordinador';
                break;
            case '5':
                profile = 'Asistente H';
                break;
            case '6':
                profile = 'Decano';
                break;
            case '7':
                profile = 'Ejecutivo VRAF';
                break;
            case '8':
                profile = 'Contabilidad';
                break;
            case '9':
                profile = 'Take Data';
                break;
            default:
                profile = 'sin perfil';
        }
        return profile;
    }

    getInfoUser = () => {
        const { username, fullName } = this.state;
        return {
            username,
            fullName
        };
    }

    close = () => {
        localStorage.clear();
    }
}

export default new Session();
