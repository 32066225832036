import React from 'react';
import PropTypes from 'prop-types';

const CardFormFiltre = (props) => {
    const {
        fields, size, className, formClassName,
        formRef, handleSubmit
    } = props;
    const dataLength = fields.length;
    return(
        <div className={`${className} col-12 bg-white shadow-sm rounded`}>
            <form
                className={`${formClassName}`}
                onSubmit={(e) => handleSubmit(e)}
                ref={formRef}
            >
                {
                    dataLength > 0 ? fields.map(((t, i) => {
                        const { field, iconCalssName } = t;
                        const icon = iconCalssName !== 'undefined' ? <i className={iconCalssName} aria-hidden="true" /> : null
                        return (
                            <div key={`crad-${i}`} className={`py-3 ml-4`} style={{width: `${size}`}}>
                                <div className="input-group my-2 input-group-sm ">
                                    {field}
                                    {icon}
                                </div>
                            </div>
                        );
                    })) : null
                }
            </form>
        </div>
    )
}

CardFormFiltre.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.object,
        fieldSize: PropTypes.number,
        iconCalssName: PropTypes.string,
    })),
    size: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    formClassName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    formRef: PropTypes.object,
};

CardFormFiltre.defaultProps = {
    fields: [],
    size: 3,
    className: "",
    formClassName: "row",
    handleSubmit: (e) => { e.preventDefault() },
    formRef: {}
};

export default CardFormFiltre;