import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            c: props.className,
        };
    }

    componentDidUpdate(prevProps) {
        const { className } = this.props;
        if (className !== prevProps.className) {
            this.loadComponent(className);
        }
    }

    loadComponent(className) {
        this.setState({
            c: className,
        });
    }

    render() {
        const { c } = this.state;
        return (
            <footer className={`bg-white layout-footer mt-3 ${c}`}>
            </footer>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
};

Footer.defaultProps = {
    className: '',
};
