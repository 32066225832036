import { Card, Center, Grid, Text } from '@mantine/core';
import React from 'react';

const DashboardCardDoble = ({ firstTitle, firstNumber, secondTitle, secondNumber, icon }) => {
    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder style={{
            
        }}>
            
            <Grid>
                <Grid.Col span={'auto'}>
                    <Text >{firstTitle}</Text>
                    <Text my={4} fw={500} size={'30px'}>{firstNumber}</Text>
                </Grid.Col>
                <Grid.Col span={'auto'}>
                    <Text >{secondTitle}</Text>
                    <Text my={4} fw={500} size={'30px'}>{secondNumber}</Text>
                </Grid.Col>
                <Grid.Col span={'content'}>
                    <Center h={'100%'}>
                        
                        {icon}
                      
                    </Center>
                </Grid.Col>
            </Grid>
        </Card>
    );

};

export default DashboardCardDoble;