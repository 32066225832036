import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Col, Row, Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import { GetRegions, GetCommunes, GetInstitution, UpdateInstitution } from '../../services/Api';

class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            institution:{
                id:0,
                address:'',
                app_front:'',
                communes_id: 0,
                region_id:0,
                domains: '',
                bill_ruts:'',
                email: '',
                name: '',
                rut_legal: '',
            },
            options:{
                regions:[],
                communes:[]
            },
            regions:[],
            communes:[],
            buttons:{
                save:true,
                apply:false
            },
            error:{
                save:true,
                apply:true,
                save_txt:'',
                apply_txt:''
            },
            loading:true
        };
        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async loadVars() {//Load CenterCost
        const communes = await GetCommunes();
        const regions = await GetRegions();
        console.log(regions);
        let institution = await GetInstitution({id:this.props.match.params.id});
        institution.region_id = communes.find((commune) => {
            return commune.id == institution.communes_id;
        }).region.id;
        const options={
            regions:regions.map((region) => {
                return {
                    value: region.id,
                    label: region.name
                }
            }),
            communes:regions.find((region)=>{
                return region.id == institution.region_id;
            }).communes.map((commune) => {
                return {
                    value: commune.id,
                    label: commune.name
                }
            })
        };
        this.setState({
            communes,
            regions,
            options,
            institution, 
            loading:false
        });
    }

    async componentDidMount() {
        this.loadVars().then(function(){
            const {institution} = this.state;
        }.bind(this))
    }

    onChange = ({target}) => {
        let {value} = target.value??null;
        const {institution, regions} = this.state;
        switch (target.type){
            case 'checkbox':
                value = target.checked;
                break;
            default:
                value = target.value;
        }
        switch (target.name){
            case 'region':
            const regionSelected = regions.filter((item) => item.id === institution.region_id);
            if (regionSelected.length > 0) {
                this.setState({communes:regionSelected[0].communes});
            }
            break;
            case 'bte_enabled':
            case 'bill_messaging':
                institution.meta[target.name] = value;
            break;
        }
        institution[target.name] = value;
        this.setState({institution});
    }

    onRegionChange = ({value,label}) => {
        const {regions,institution,options} = this.state;
        let region = regions.find((region) => {
            return region.id==value;
        });
        institution.region_id=value;
        institution.communes_id = 0;
        options.communes = region.communes.map((commune)=>{
            return {
                value: commune.id,
                label: commune.name
            };
        });
        this.setState({
            options
        });
    }

    onCommuneChange = ({value}) => {
        const {institution} = this.state;
        institution.communes_id = value;
        this.setState({institution});
    }

    async save (){
        const {institution} = this.state;
        const response = await UpdateInstitution(institution);        
        window.history.pushState({},"",'/holding/edit/'+institution.holding_id);
        this.props.history.push('/holding/edit/'+institution.holding_id);
    }

    render() {
        const {
            institution,
            regions,
            communes,
            error,
            loading,
            options
        } = this.state;
        const { onChange } = this;
        let region = regions.find((item)=>{
            return item.value == institution.region_id;
        });
        let region_inicial;
        if (region){
            region_inicial = { label:region.name,value:region.id};
        }
        return (!loading&&institution)?(
            <>
                <ToastContainer 
                autoClose={5000}
                pauseOnHover
                />
                <Row as="form">
                    <Col>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                        placeholder="Nombre"
                        name="name"
                        defaultValue={institution.name}
                        onChange={onChange}
                        isInvalid={error.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            Debe introducir un nombre
                        </Form.Control.Feedback>
                    </Col>
                    <Col>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                        placeholder="Dirección"
                        name="address"
                        defaultValue={institution.address}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Región</Form.Label>
                        <Select name="region_id" value={options.regions.find((region)=>{
                            return region.value == institution.region_id;
                        })} placeholder="Seleccione una región" options={options.regions} className="input-field select" onChange={this.onRegionChange} />
                    </Col>
                    <Col>
                        <Form.Label>Comuna</Form.Label>
                        <Select name="communes_id" value={options.communes.find((commune)=>{
                            return commune.value == institution.communes_id;
                        })} placeholder="Seleccione una comuna" options={options.communes} className="input-field select" onChange={this.onCommuneChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Rut</Form.Label>
                        <Form.Control
                        placeholder="Rut"
                        name="rut_legal"
                        defaultValue={institution.rut_legal}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                        placeholder="Dirección"
                        name="email"
                        defaultValue={institution.email}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                        placeholder="ej.: uah"
                        name="slug"
                        defaultValue={institution.slug}
                        onChange={onChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check type="checkbox" name="bte_enabled" checked={institution.meta.bte_enabled} onChange={onChange} label="Emisor BTE" className="mr-2"/>
                        <Form.Check type="checkbox" name="bill_messaging" checked={institution.meta.bill_messaging} onChange={onChange} label="Mensajería Pago" className="mr-2"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right mt-3">
                        <Button variant="success" readOnly onClick={this.save} as="input" value="Guardar"/>
                    </Col>
                </Row>
            </>
        ):(
            <div className="text-center col pt-5">
                <Spinner />
                <h5>Cargando datos..</h5>
            </div>
        );
    }
}
export default Update;
Update.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};