import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreateUser from './Create';
import UpdateUser from './Update';
import { BillsStats, ListUserAll, UpdateUser as UpdateUserApi } from '../../services/Api';
import FormProcessing from '../../services/FormProcessing';
import Session from '../../services/Session';
import axios from 'axios';
import {
	SelectCustom, CardStats, TableCustom,
	PaginateCustom, Loading, CardFormFiltre
} from '../../ComponentsHtml/AllHtml';

export default class UserList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listUsers: [],
			userList: [],
			selectStatus: [],
			selectYearsMonth: [],
			pagination: null,
			loading: true,
			showCreate: false,
			showUpdate: false,
			userUpdate: 0,
		};
		this.listUsers = this.listUsers.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.disableUser = this.disableUser.bind(this);
		this.formSearch = React.createRef();
	}
	
	async componentDidMount() {
		this.listUsers();
	}

	async disableUser(id) {
		this.setState({ loading: true });
		const disable = await UpdateUserApi({id:id,status:2});
		this.listUsers();
		alert("Usuario dehabilitado");
	}

	async editUser(id) {
		this.setState({ userUpdate:id });
		this.setState({ showUpdate: true });
	}
	
	ExportUsers = () => {
		const BACK_URL = process.env.REACT_APP_API_URL;
		axios({
			method: 'get',
			url: BACK_URL+'superadmin/users/list/export', 
			headers: {
				'Content-Type': 'application/vnd.ms-excel',
				'Accept': 'application/vnd.ms-excel',
				'Authorization': Session.getTokenUser(),
				'Token-Api': 'BACK_TOKEN',
				'Language': 'es',
				'Token-User': Session.getTokenUser(),
				'Status-User': Session.getStatusUser(),
				'Type-Profile-User': Session.getTypeProfileUser(),
				'Content-Disposition': "attachment; filename=template.xlsx",
			},
			responseType: 'arraybuffer'
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Usuarios.xlsx');
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(url);
		})
		.catch((error) => console.log(error));
	}

	async handleFilter(e) {
		e.preventDefault();
		this.setState({ onLoadingTable: true, services: [] });

		const formSearch = await FormProcessing.toObject(this.formSearch.current);
		const urlParams = new URLSearchParams(window.location.search);
		Object.entries(formSearch).map((data) => {
			urlParams.set(data[0], data[1]);
		});
		urlParams.delete('page');
		this.props.history.push(`${window.location.pathname}?${urlParams.toString()}`);
		this.listUsers();
	}

	async listUsers(page = {}) {
		this.setState({ loading: true, showUpdate: false });
		const userList = await ListUserAll(page);
		this.setState({
			userList: userList.data,
			selectStatus: userList.selectStatus,
			selectYearsMonth: userList.selectYearsMonth,
			pagination: {
				last_page: userList.last_page,
				current_page: userList.current_page,
				per_page: userList.per_page,
			},
			loading: false
		});
	}

	create = {
		show: function (scope) {
			scope.setState({ showCreate: true })
		},
		hide: function (scope) {
			scope.setState({ showCreate: false })
		}
	}

	update = {
		show: function (scope) {
			scope.setState({ showUpdate: true })
		},
		hide: function (scope) {
			scope.setState({ showUpdate: false })
		}
	}

	render() {
		const { loading, userList, pagination, showCreate, showUpdate } = this.state;
		return (<>

			<div className="row">
				<div className="col text-right">
					<Button onClick={this.ExportUsers} className="btn btn-success btn-sm mr-3"> <i className="fa fa-file-download"></i> Exportar </Button>
				</div>
			</div>

			<div className="row">
				<div className="col-12 mt-3">
					<CardFormFiltre
						className="mb-3"
						handleSubmit={this.handleFilter}
						fields={[
							{
								field: <>
									<input
										type="text"
										className="form-control"
										placeholder="Rut o Nombre..."
										aria-label="Buscar"
										aria-describedby="basic-addon1"
										name="s"
									/>
									<div className="input-group-append">
										<button
											className="btn btn-outline-secondary px-4"
											type="submit"
										>
											<i className="fa fa-search" aria-hidden="true" />
										</button>
									</div>
								</>,
							},
						]}
						size='lg'
						formRef={this.formSearch}
					/>
					<TableCustom
						data={userList}
						onLoadingTable={loading}
						trHeader={[
							{ thClassName: 'samll px-2', content: '#' },
							{ thClassName: 'samll px-2', content: 'Nombre' },
							{ thClassName: 'samll px-2', content: 'RUT' },
							{ thClassName: 'samll px-2', content: 'Holding' },
							{ thClassName: 'samll px-2', content: 'Cargo' },
							{ thClassName: 'samll px-2', content: 'Perfil' },
							{ thClassName: 'samll px-2', content: 'Email' },
							{ thClassName: 'samll px-2', content: 'Estado' },
							{ thClassName: 'samll px-2', content: 'F.Creación' },
							{ thClassName: 'samll px-2', content: 'Opciones' }
						]}
						trBody={[
							{ tdClassName: "small px-2", attrData: "id" },
							{
								tdClassName: "small px-2",
								attrData: "full_name",
								content: (item, row) => {
									return (
										<label>
											<a href="#" onClick={this.editUser.bind(this,row.id)}>
												{item}
											</a>
										</label>
									)
								}
							},
							{ tdClassName: "small px-2", attrData: "rut" },
							{ tdClassName: "small px-2", attrData: "holding.name" },
							{ tdClassName: "small px-2", attrData: "position" },
							{ tdClassName: "small px-2", attrData: "profile.name" },
							{ tdClassName: "small px-2", attrData: "email" },
							{ tdClassName: "small px-2", attrData: "status_name" },
							{ tdClassName: "small px-2", attrData: "created_at" },
							{
								tdClassName: "small px-2", attrData: "", content: (undefined, row) => {
									return (
										<>
											<button className="btn btn-success btn-sm py-0 mr-1" type="button" onClick={this.editUser.bind(this,row.id)}><i className="fa fa-pencil" /></button>

											<button className="btn btn-danger btn-sm py-0" type="button" onClick={this.disableUser.bind(null,row.id)}><i className="fa fa-trash" /></button>
										</>
									);
								}
							},
						]}
					/>
					{
						pagination != null ? (
							<PaginateCustom
								last_page={pagination.last_page}
								current_page={pagination.current_page}
								per_page={pagination.per_page}
								loadList={this.listUsers}
							/>
						) : null
					}
				</div>

				<Modal size="lg" show={showCreate} onHide={this.create.hide.bind(null, this)}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Usuario</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CreateUser onClose={this.create.hide.bind(null, this)} />
					</Modal.Body>
				</Modal>

				<Modal size="lg" show={showUpdate} onHide={this.update.hide.bind(null, this)}>
					<Modal.Header closeButton>
						<Modal.Title>Editar Usuario</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<UpdateUser userID={this.state.userUpdate} onClose={this.update.hide.bind(null, this)} onUpdate={this.listUsers} />
					</Modal.Body>
				</Modal>
			</div>
		</>
		);
	}
}
UserList.propTypes = {};