import styled from 'styled-components';
import { Card, Image } from 'react-bootstrap';

export const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

export const Name = styled.div`
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 20px;
  fonr-weight: 600;
  font-weight: bold;
  text-align: left;
`;
export const Status = styled.div`
background-color: #20ce88;
margin-top: 5px;
margin-left: 8px;

`;

export const CardS = styled(Card)`
    margin: 1%;
`;
export const UserImg = styled(Image)`
alignSelf: center;
text-align: center;
`;

export const Subtitle = styled.div`
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
`;
export const Number = styled.div`
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 64px;
  font-weight: 900;
  line-height: 80px;
  text-align: left;
  color: #f05032;
`;

export const CardClick = styled(Card)`
    cursor: pointer;
    margin: 1%;
`;
