import { Card, Center, Grid, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';


const DashboardCard = ({ title, number, icon, onClick, primaryColor, secondaryColor, active }) => {
    const [actived, setActived ] = useState(false);
  
    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };

    useEffect(() => {
      setActived(active);
    }, [active]);
  
    return (
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        onClick={handleClick}
        style={{
          cursor: onClick ? 'pointer' : 'default',
          backgroundColor: actived ? secondaryColor : primaryColor,
          transition: 'background-color 0.3s, color 0.3s',
          userSelect: 'none'
        }}
      >
        <Grid>
          <Grid.Col style={{
            color: actived ? '#FFFFFF' : '#000000',
          }} span={'9'}>
            <Text>{title}</Text>
            <Text my={4} fw={500} size={'30px'}>{number}</Text>
          </Grid.Col>
          <Grid.Col span={'auto'}>
            <Center style={{
                stroke: actived ? primaryColor : secondaryColor,
            }}
            h={'100%'}>
              {React.cloneElement(icon, {
                style: {
                  stroke: actived ? primaryColor : secondaryColor,
                  transition: 'fill 0.3s'
                }
              })}
            </Center>
          </Grid.Col>
        </Grid>
      </Card>
    );
  };

export default DashboardCard;