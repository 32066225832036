import React, { Component } from 'react';
import {
    Carousel, Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CardClick } from '../../styles/Users';
import CardUserInfo from '../../components/Cards/CardUserInfo';
import CardService from '../../components/Cards/CardService';

const mql = window.matchMedia('(min-width: 800px)');


class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // userid: props.match.params.user_id,
            userdata: {},
            loading: false,
            docked: mql.matches,
        };
        this.getUser = this.getUser.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    }

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);
        this.getUser();
        this.setState({ loading: true });
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }


    getUser() {
        this.setState({
            userdata: {
                id: 1,
                name: 'Raimundo Marin',
                rol: 'Ayudante',
                email: 'rjmarin@gux.cl',
                phone: '+56994823148',
                status: 'Activo',
                services:
                    [{
                        id: 1,
                        name: 'Ayudantia de Calculo',
                        description: 'Servicios educacional clases de Ingenieria Comercial para primer semestre  facultada de derecho Universidad Alberto Hurtado',
                        contract: true,
                        status: 'Activo',
                        bills: [
                            {
                                id: 1, status: 'Pagada', gross_amount: 1000000, date: '17/02/2020',
                            },
                            {
                                id: 4, status: 'Pagada', gross_amount: 5000000, date: '17/02/2020',
                            },
                            {
                                id: 2, status: 'Pendiente', gross_amount: 1000000, date: '17/02/2020',
                            },
                            {
                                id: 3, status: 'Proyectada', gross_amount: 1000000, date: '17/02/2020',
                            },
                        ],

                    },
                    {
                        id: 2,
                        name: 'Ayudantia de Programacion',
                        description: 'Servicios educacional clases de Ingenieria Civil para primer semestre  facultada de Ingenieria Universidad Alberto Hurtado',
                        contract: true,
                        status: 'Activo',
                        bills: [
                            {
                                id: 1, status: 'Pagada', gross_amount: 100000, date: '17/02/2020',
                            },
                            {
                                id: 4, status: 'Pendiente', gross_amount: 300000, date: '17/02/2020',
                            },
                            {
                                id: 2, status: 'Pendiente', gross_amount: 200000, date: '17/02/2020',
                            },
                            {
                                id: 3, status: 'Proyectada', gross_amount: 100000, date: '17/02/2020',
                            },
                        ],

                    }],

            },
        });
    }

    mediaQueryChanged() {
        this.setState({
            docked: mql.matches,
        });
    }

    render() {
        const { userdata, loading, docked } = this.state;
        if (loading && docked) {
            return (
                <div>
                    <CardUserInfo userdata={userdata} />
                    <Carousel>
                        {userdata.services.map((userservice) => (
                            <Carousel.Item key={userservice.name + userservice.id}>
                                <CardService
                                    userservice={userservice}
                                    key={userservice.name + userservice.id}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>

                </div>

            );
        }
        if (loading) {
            return (
                <div>
                    <CardUserInfo userdata={userdata} />
                    {userdata.services.map((userservice) => (
                        <Link
                            to={{
                                pathname:
                                `/servicios/${
                                    userservice.id}`,
                            }}
                            query={{
                                userservice,
                            }}

                        >
                            <CardClick>
                                <Card.Body>
                                    <Card.Title>{`#${userservice.id} ${userservice.name}`}</Card.Title>
                                    <Card.Text className="text-muted">{userservice.status}</Card.Text>
                                </Card.Body>
                            </CardClick>
                        </Link>
                    ))}


                </div>
            );
        }
        return (<div />);
    }
}

export default User;
