import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store, { saveState } from './redux/store';
import AppRouteUsers from './AppRouteUsers';
import AppRouterAuth from './AppRouterAuth';
// import './App.scss';
import Session from './services/Session';

class App extends Component {
    componentDidMount() {
        window.addEventListener('unload', saveState);
    }
    render() {
        return (
            <div className="container-fluid px-5">
            <Provider store={store}>
                { 
                    Session.isAuth() ?
                    <AppRouteUsers /> :
                    <AppRouterAuth />
                }
            </Provider>
            </div>
        );
    }
}

export default App;
