import React, { Component } from 'react';
import SiedebarLeft from './Components/SiedebarLeft';
import NavBarTop from './Components/NavBarTop';
import Footer from './Components/Footer';
import { SnackbarProvider } from 'notistack';

export default class TemplatePrivate extends Component {
    constructor(props) {
        super(props);
        let sOpen = 'open';
        if ('sidebarOpen' in localStorage) {
            sOpen = localStorage.getItem('sidebarOpen');
        }
        this.state = {
            sOpen,
        };
        this.sidebarClose = this.sidebarClose.bind(this);
    }

    sidebarClose() {
        this.setState({
            sOpen: localStorage.getItem('sidebarOpen'),
        });
    }

    render() {
        const classCont = 'layout-main px-3 h-100';
        const classFoo = 'layout-footer';
        const { children } = this.props;
        const { sOpen } = this.state;
        const notistackRef = React.createRef();
        const onClickDismiss = key => () => { 
            notistackRef.current.closeSnackbar(key);
        }
        return (
            <SnackbarProvider
                maxSnack={12}
                preventDuplicate
                ref={notistackRef}
                action={(key) => (
                    <button
                        type="buttom" onClick={onClickDismiss(key)}
                        className="btn rounded-circle border py-0 px-2 text-white"
                    >
                        x
                    </button>
                )}
            >
                <div className="container-fluid h-100 pr-0" id="container-main">
                    <SiedebarLeft sidebarClose={this.sidebarClose} />
                    <NavBarTop />
                    <div className={sOpen === 'open' ? classCont : `${classCont} layout-sidebar-close`}>
                        {children}
                    </div>
                    <Footer className={sOpen === 'open' ? classFoo : `${classFoo} layout-sidebar-close`} />
                </div>
            </SnackbarProvider>

        );
    }
}
