import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { getNationality } from '../../services/Api';

export default class ListNationalities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nationalities: [],
            loading: true,
        };
        this.listList = this.listList.bind(this);
    }

    async componentDidMount() {
        const { attachmentId } = this.state;
        this.listList({});
    }

    async listList() {
        const n = await getNationality();
        this.setState({
            loading: false,
            nationalities: n,
        });
    }
    render() {
        const {
            nationalities,
            loading,
        } = this.state;
        if (loading === true) {
            return (
                <div className="text-center col pt-5">
                    <Spinner />
                    <h5>Cargando nacionalidades..</h5>
                </div>
            );
        }
        const TrTemplate = (t, i) => {
            const index = i + 1;
            return (
                <tr key={`template-${index}`}>
                    <td className="small">{i}</td>
                    <td className="small">{t}</td>
                </tr>
            );
        };
        const TrEmpty = () => {
            const message = 'Sin contratos';
            return (
                <tr>
                    <td colSpan="7" className="text-center">
                        <i className="fa fa-ban" />
                        <br />
                        {message}
                    </td>
                </tr>
            );
        };
        return (
            <div className="row">
                <div className="col-12">
                    <h5 className="mt-5">
                        Nacionalidades
                        <button
                                type="button"
                            className="btn btn-success btn-sm ml-3"
                            onClick={() => alert('in porgress')}
                        >
                            agregar
                        </button>
                    </h5>
                    <hr />
                    <table className="table table-hover table-custom">
                        <thead className="bg-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nacionalidad</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {
                                nationalities.length > 0 ? nationalities.map(((t, i) => {
                                    const data = TrTemplate(t, i);
                                    return data;
                                }), this) : <TrEmpty />
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}