/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SystemHoldingsList } from '../services/Api';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            system_institutions: [],
            zero: 0,
            system_holdings: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        SystemHoldingsList().then((response) => {
            this.setState({ system_holdings: response });
        });
    }

    render() {
        const {
            system_holdings, system_institutions, other_holdings, zero,
        } = this.state;
        return (
            <div className="row">
                <div className="col-12 py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm text-center">
                        <strong>Holdings en el sistema</strong>
                        <h1 className="text-dark font-weight-bold mb-0">
                            {system_holdings.length}
                        </h1>
                    </div>
                </div>
                <div className="col py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm">
                        <strong>Instituciones</strong>
                        <br />
                        <span>en el sistema</span>
                        <h1 className="text-dark font-weight-bold mb-0 text-right">
                            {system_institutions.length}
                        </h1>
                    </div>
                </div>
                <div className="col py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm">
                        <strong>Servicios</strong>
                        <br />
                        <span>rechazados</span>
                        <h1 className="text-dark font-weight-bold mb-0 text-right">
                            {zero}
                        </h1>
                    </div>
                </div>
                <div className="col py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm">
                        <strong>Boletas</strong>
                        <br />
                        <span>anuladas</span>
                        <h1 className="text-dark font-weight-bold mb-0 text-right">
                            {zero}
                        </h1>
                    </div>
                </div>
                <div className="col py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm">
                        <strong>Boletas</strong>
                        <br />
                        <span>pagadas</span>
                        <h1 className="text-dark font-weight-bold mb-0 text-right">
                            {zero}
                        </h1>
                    </div>
                </div>
                <div className="col py-3 px-2">
                    <div className="border bg-white p-3 rounded-lg shadow-sm">
                        <strong>Boletas</strong>
                        <br />
                        <span>por pagar</span>
                        <h1 className="text-dark font-weight-bold mb-0 text-right">
                            {zero}
                        </h1>
                    </div>
                </div>
                <div className="col-12 py-3 px-2">
                    <div className="border bg-white p-5 rounded-lg shadow-sm">
                        <p className="px-2">
                            <strong>Holdings </strong>
                            en el sistema
                        </p>
                        <hr />
                        <table className="table table-borderless text-center">
                            <thead>
                                <tr>
                                    <th>
                                        Nombre
                                    </th>
                                    <th>
                                        Dominios
                                    </th>
                                    <th>
                                        Dominio Front
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {system_holdings && system_holdings.map((nt) => (
                                    <tr key={nt.id}>
                                        <td>
                                            {nt.name}
                                        </td>
                                        <td>
                                            {nt.domains}
                                        </td>
                                        <td>
                                            {nt.app_front}
                                        </td>
                                        <td>
                                            <Link
                                                className="btn btn-success btn-sm mr-3"
                                                to={'/holding/edit/' + nt.id}
                                            > <i className="fa fa-pencil"></i>
                                            </Link>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}


export default Home;
