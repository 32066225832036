import { SystemHoldingsList } from '../../../services/Api';
import useSWR from 'swr';


const useHoldings = () => {

    const getHoldingIndex = () => {
        
        const { data, error, isLoading } = useSWR(
            'superadmin/holding/system/list', 
            (url) => SystemHoldingsList(url)
        ); 

        return {
            holdings: data || [],
            isLoadingHoldings: isLoading,
            isErrorHoldings: error,
        };

    };
    
    return {
        getHoldingIndex
    };
      
};

export default useHoldings;
