import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import '../node_modules/font-awesome/css/font-awesome.min.css';
//import 'rsuite/dist/styles/rsuite-default.css';
import './styles-all/main.scss';
import * as serviceWorker from './serviceWorker';

// Mantine {
import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';

// Configuración de tema en Mantine
const theme = createTheme({
    colorScheme: 'light',
});
//}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <MantineProvider theme={theme}>
            <App />
        </MantineProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
