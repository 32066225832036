import styled from 'styled-components';
import { Form, Card } from 'react-bootstrap';

export const Title = styled.div`
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
`;
export const StatusGreen = styled.div`
background-color: #20ce88;
width: 50%;
min-width: 70px;
margin-top: 5px;
margin-left: 8px;
text-align: center;

`;

// Search.jsx
export const SearchInput = styled(Form.Control)`
display: inline-block;
height: 38px;
margin-bottom: 20px;
margin-right: 20px;
padding-left: 10px;
width: 200px;

 `;

export const StatusYellow = styled.div`
background-color: #fed12e;
min-width: 70px;
width: 50%;
margin-top: 5px;
margin-left: 8px;
text-align: center;

`;

export const StatusBlue = styled.div`
background-color: #3d9be9;
width: 50%;
min-width: 70px;
margin-top: 5px;
margin-left: 8px;
text-align: center;

`;

export const Number = styled.div`
  height: 58px;
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 46px;
  font-weight: 900;
  line-height: 58px;
  text-align: left;
`;

export const CardS = styled(Card)`
    margin: 1%;
`;
export const CardB = styled(Card)`
    margin: 2%;
    min-width: 200px;
    min-height: 250px; 
`;
