import Fetch from './Fetch';

let memoize = function(method, force=false) {
    let cache = {};
    
    return async function() {
        let args = JSON.stringify(arguments);
        cache[args] = force? method.apply(this, arguments) : cache[args] || method.apply(this, arguments);
        return cache[args];
    };
}

export const searchUser = memoize(async function (params) {
    return await Fetch.put('search/user', params);
});

export async function findForMailOrRut(params) {
    return await Fetch.get('search/for/mail/rut', params);
}

export const getNationality = memoize(async function (params) {
    return await Fetch.get('list/nationality', params);
});


export async function SignIn(params) {
    return await Fetch.post('sign/in', params);
}

export async function signUp(params) {
    return await Fetch.post('sign/up', params);
}

export async function CheckTokenReset(tokenReset) {
    return await Fetch.get(`check/token/${tokenReset}`);
}

export async function MyProfile(params = {}) {
    return await Fetch.get('my/profile', params);
}

export const GetCommunes = memoize(async function (params = {}) {
    return await Fetch.get('communes', params);
});

export const GetRegions = memoize(async function (params = {}) {
    return await Fetch.get('regions', params);
});

export const GetBanks = memoize(async function (params = {}) {
    return await Fetch.get('banks', params);
});

export async function SystemHoldingsList() {
    return await Fetch.get('superadmin/holding/system/list');
}

export async function GetHolding(params = {}) {
    return await Fetch.post('superadmin/holding/get', params);
}
export async function UpdateHolding(params = {}) {
    return await Fetch.post('superadmin/holding/update', params);
}

export async function InstitutionsList(params = {}) {
    return await Fetch.get('superadmin/institution/list', params);
}

export async function GetInstitution(params = {}) {
    return await Fetch.post('superadmin/institution/get', params);
}
export async function UpdateInstitution(params = {}) {
    return await Fetch.post('superadmin/institution/update', params);
}

export async function GetUsersInstitution(params = {}) {
    return await Fetch.get('users/institution', params);
}

export async function GetUsersDashboard(params = {}) {
    return await Fetch.get('users/dashboard', params);
}

// Setting
export async function generateExcelContractPreload(params = {}) {
    return await Fetch.post('generate/excel/contract/preload', params);
}

export async function CreateUser(params = {}) {
    return await Fetch.post('users/create', params);
}

export async function GetUser(params = {}) {
    return await Fetch.post('users/get', params);
}

export async function UpdateUser(params = {}) {
    return await Fetch.post('superadmin/users/update', params);
}

export async function ListUser(params = {}) {
    return await Fetch.get('users/list', params);
}

export async function ListUserAll(params = {}) {
    return await Fetch.post('superadmin/users/list/all', params);
}

export async function AssignRole(params = {}) {
    return await Fetch.post('assign/role', params);
}

export async function ListNotifications(params = {}) {
    return await Fetch.get('list/notifications', params);
}

export async function MarkAsRead(params = {}) {
    return await Fetch.get('notifications/mark-as-read', params);
}

export async function invitateUserMarket(params = {}) {
    return await Fetch.post('invitate/user/market', params);
}

//BILLS
export async function BillsIndex(url = 'superadmin/bteLog', params = {}) {
    return await Fetch.get(url, params);
}

export async function BillsTakedataIndex(url = 'superadmin/bill_takedata', params = {}) {
    return await Fetch.get(url, params);
}

export async function InstitutionsIndex(url = 'superadmin/institution/list', params = {}) {
    return await Fetch.get(url, params);
}

export async function BillsExport(params = {}) {
    return await Fetch.get('superadmin/bteLog/export', params);
}

export async function BillsShow(params = {}) {
    return await Fetch.get('bills/${id}', params);
}