import React, { Component, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { Spinner } from 'reactstrap';
import * as rut from 'rut.js';

import AlertSystem from '../../layout/AlertSystem';
import { UpdateUser as UpdateUserApi, GetUser, AssignRole } from '../../services/Api';
import FormProcessing from '../../services/FormProcessing';

class UpdateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            loading: false,
            message: 'Componente SignIn',
            type: 'warning',
            userData: {
                profile_id: 4
            },
            resetForm: false,
            autoComplete: '',
            dataAutoComplete: []
        };
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.loadUser();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.userID!==prevProps.userID){
            this.loadUser();
        }
    }    

    async loadUser() {
		const user = await GetUser({id:this.props.userID});
        if (user) {
            const profile = {...user};
            profile.rut = rut.format(profile.rut)
            profile.profile_id=4;
            this.setState({ userData:profile });
        }
    }
    
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        const { userData } = this.state;
        const data = await FormProcessing.toObject(this.form.current);
        let validate = [];
        let response = {};
        data.id=userData.id;
        response = await UpdateUserApi(data);
        if (response.enable_validations === true) {
            validate = response;
        }
        FormProcessing.validate(this.form.current, validate);
        if (response.error) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.error,
                type: 'danger',
            });
        } else if (response.success) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.success,
                type: 'success',
            });
        } else {
            this.setState({
                loading: false,
            });
        }
        await this.props.onUpdate();
    }

    render() {
        const {
            showAlert, message,type,
            loading, userData
        } = this.state;
        
        const BtnSpinner = () => {
            const style = {
                top: '-2px',
                position: 'relative',
                right: '9px',
            };
            return (
                <>
                    <Spinner
                        size="sm"
                        color="balck"
                        style={style}
                    />
                    Guardando..
                </>
            );
        };
        return (
            <div className="row justify-content-md-center pt-5">
                <div className="col-12">
                    <AlertSystem show={showAlert} type={type} message={message} />
                </div>
                <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    ref={this.form}
                    className="col-md-7 bg-white px-5 py-3 rounded shadow-sm"
                >
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Nombre
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="name"
                                className="form-control form-control-sm"
                                placeholder="Nombre"
                                aria-label="name"
                                aria-describedby="name"
                                defaultValue={userData.name ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Apellido paterno
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="paternal_surname"
                                className="form-control form-control-sm"
                                placeholder="Apellido paterno"
                                aria-label="paternal_surname"
                                aria-describedby="paternal_surname"
                                defaultValue={userData.paternal_surname ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Rut
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="rut"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu rut"
                                aria-label="rut"
                                aria-describedby="rut"
                                defaultValue={userData.rut ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Correo
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="email"
                                name="email"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu correo"
                                aria-label="email"
                                aria-describedby="email"
                                defaultValue={userData.email ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Telefono(principal)
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="main_phone"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu telefono"
                                aria-label="main_phone"
                                aria-describedby="main_phone"
                                defaultValue={userData.main_phone ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Cargo
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="position"
                                className="form-control form-control-sm"
                                placeholder="Cargo en el Holding"
                                aria-label="position"
                                aria-describedby="position"
                                defaultValue={userData.position ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group text-center pt-3">
                        <button
                            type="submit"
                            className="btn btn-success"
                            disabled={loading}
                        >
                            { loading ? <BtnSpinner /> : 'Guardar' }
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}
export default translate(UpdateUser);
