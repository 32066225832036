import useSWR from 'swr';
import { BillsTakedataIndex,BillsIndex, BillsShow } from '../../../services/Api';


const useBills = () => {
  // Método para obtener la lista de facturas
  const getBillsIndex = (page = 1, page_size = 10, sortBy= '', reversed=true, search='', holding = '',institution = '', period ='', daysFilter ='') => {
    const params = {
      page: page,
      page_size : page_size,
      sortBy: sortBy,
      search: search,
      reversed: reversed,
      holding_id: holding.id,
      institution_id: institution.id,
      daysFilter: daysFilter,
      period: period,
    }

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      'superadmin/bteLog', 
      (url) => BillsIndex(url,params)
    );    
    
  
    return {
      paginatedData: data?.paginatedData || null,
      resume : data?.summary,
      isLoading: isLoading,
      isValidatingIndex: isValidating,
      isError: error,
      mutate,
    };
  };


  // Método para obtener detalles de una factura por su ID
  const getBillById = (id) => {
    const { data, error } = useSWR('/bills/${id}', BillsShow);

    return {
      bill: 'data?.data || {}',
      isLoading: '!error && !data',
      isError: 'error',
    };
  };

  const getBillTakedataIndex = (page = 1, page_size = 10, sortBy= '', reversed=true, search='', holding = '',institution = '', period ='2024', daysFilter ='') => {
    const params = {
      page: page,
      page_size : page_size,
      sortBy: sortBy,
      search: search,
      reversed: reversed,
      holding_id: holding.id,
      institution_id: institution.id,
      daysFilter: daysFilter,
      period: period,
    }

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      'superadmin/bill_takedata', 
      (url) => BillsTakedataIndex(url,params)
    );    
    
  
    return {
      paginatedData: data?.paginatedData || null,
      resume : data?.summary,
      isLoading: isLoading,
      isValidatingIndex: isValidating,
      isError: error,
      mutate,
    };
  };

  return {
    getBillTakedataIndex,
    getBillsIndex,
    getBillById
  };
};

export default useBills;