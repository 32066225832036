import React, { Component } from 'react';
import { translate } from 'react-switch-lang';
import { Spinner } from 'reactstrap';

import AlertSystem from '../../layout/AlertSystem';
import { CreateUser as CreateUserApi, findForMailOrRut, AssignRole } from '../../services/Api';
import FormProcessing from '../../services/FormProcessing';
import { AutoComplete } from 'rsuite';

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            loading: false,
            message: 'Componente SignIn',
            type: 'warning',
            userData: {
                profile_id: 4
            },
            resetForm: false,
            autoComplete: '',
            dataAutoComplete: [],
            searching: false,
        };
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeAutoComplete = this.handleChangeAutoComplete.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    componentDidMount() {
        this.loadUser();
    }

    async handleChangeAutoComplete(autoComplete){
        if(typeof autoComplete === 'string'){
            this.setState({
                searching:true,
            });
            const { dataAutoComplete } = this.state;
            const res = await findForMailOrRut({autoComplete});
            this.setState({
                autoComplete,
                dataAutoComplete:res.results,
                searching:false,
            });
        }
        if(typeof autoComplete === 'object'){
            this.form.current.reset();
            console.log(autoComplete)
            this.setState({
                userData:autoComplete.user
            });
        }
    }

    clearForm(){
        this.form.current.reset();
        this.setState({
            userData:{}
        });
    }
    
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        const { userData } = this.state;
        const data = await FormProcessing.toObject(this.form.current);
        let validate = [];
        let response = {}
        if(Object.entries(userData).length === 0) {
            response = await CreateUserApi(data);
        } else {
            data.user_id=userData.id;
            response = await AssignRole(data);
        }
        if (response.enable_validations === true) {
            validate = response;
        }
        FormProcessing.validate(this.form.current, validate);
        if (response.error) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.error,
                type: 'danger',
            });
        } else if (response.success) {
            this.setState({
                showAlert: true,
                loading: false,
                message: response.success,
                type: 'success',
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        const {
            showAlert, message,type,
            loading, resetForm, userData,
            autoComplete, dataAutoComplete,
            searching,
        } = this.state;
        const SearchSpinner = () => {
            const style = {
                top: '-2px',
                position: 'relative',
                right: '9px',
            };
            return (
                <>
                    <Spinner
                        size="sm"
                        color="balck"
                        style={style}
                    />
                    Buscando..
                </>
            );
        };
        
        const BtnSpinner = () => {
            const style = {
                top: '-2px',
                position: 'relative',
                right: '9px',
            };
            return (
                <>
                    <Spinner
                        size="sm"
                        color="balck"
                        style={style}
                    />
                    Guardando..
                </>
            );
        };
        return (
            <div className="row justify-content-md-center pt-5">
                <div className="col-12">
                    <AlertSystem show={showAlert} type={type} message={message} />
                </div>
                <form
                    onSubmit={(e) => this.handleSubmit(e)}
                    ref={this.form}
                    className="col-md-7 bg-white px-5 py-3 rounded shadow-sm"
                >
                    <div className="form-group row mt-3">
                        <div className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            {searching ? <SearchSpinner/> : 'Buscar'}
                        </div>
                        <div className="col-sm-9 px-0">
                            <AutoComplete
                                data={dataAutoComplete}
                                onChange={this.handleChangeAutoComplete}
                                onSelect={this.handleChangeAutoComplete}
                                renderItem={item => {
                                    return (
                                        <div>
                                            Email: {item.label}
                                            <br/>
                                            Rut:{item.user.rut}
                                        </div>
                                    );
                                }}
                                placeholder="Rut o email"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Nombre
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="name"
                                className="form-control form-control-sm"
                                placeholder="Nombre"
                                aria-label="name"
                                aria-describedby="name"
                                defaultValue={userData.name ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Apellido paterno
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="paternal_surname"
                                className="form-control form-control-sm"
                                placeholder="Apellido paterno"
                                aria-label="paternal_surname"
                                aria-describedby="paternal_surname"
                                defaultValue={userData.paternal_surname ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Rut
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="rut"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu rut"
                                aria-label="rut"
                                aria-describedby="rut"
                                defaultValue={userData.rut ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Correo
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="email"
                                name="email"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu correo"
                                aria-label="email"
                                aria-describedby="email"
                                defaultValue={userData.email ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Telefono(principal)
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="main_phone"
                                className="form-control form-control-sm"
                                placeholder="Ingresa tu telefono"
                                aria-label="main_phone"
                                aria-describedby="main_phone"
                                defaultValue={userData.main_phone ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="col-sm-3 col-form-label px-0 col-form-label-sm">
                            Cargo
                        </p>
                        <div className="col-sm-9 px-0">
                            <input
                                type="text"
                                name="position"
                                className="form-control form-control-sm"
                                placeholder="Cargo en el Holding"
                                aria-label="position"
                                aria-describedby="position"
                                required
                                defaultValue={userData.position ?? ''}
                            />
                        </div>
                    </div>
                    <div className="form-group text-center pt-3">
                        <input type="hidden" name="profile_id" value='4'/>
                        <button
                            type="submit"
                            className="btn btn-success"
                            disabled={loading}
                        >
                            { loading ? <BtnSpinner /> : 'Crear' }
                        </button>
                        <button
                            type="button"
                            className="btn btn-black ml-2"
                            onClick={this.clearForm}
                        >
                            Limpiar
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}
export default translate(CreateUser);
