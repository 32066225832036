import React from 'react';
function Footer1() {
    return (
        <div className="col-12 fixed-bottom">
            <div className="row">
                <div className="col-6">
                    <p>
                        Copyright © Santiago Chile
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Footer1;