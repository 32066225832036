import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputFileImg extends Component {
    constructor(props) {
        super(props);
        const { srcInput, nameFile, iconClass } = props;
        this.state = {
            accept: [
                '.jpg',
                '.png',
                '.jpeg',
            ],
            [props.name]: null,
            nameFile: nameFile !== 'undefined' ? nameFile : null,
            srcInput: srcInput !== 'undefined' ? srcInput : null,
            iconClass,
        };
        this.handleChange = this.handleChange.bind(this);
        this.setNameFile = this.setNameFile.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { srcInput, nameFile, reset } = this.props;
        if (reset !== prevProps.reset) {
            const imgtag = document.getElementById('preview-q34e45');
            const input = document.getElementById('input-q34e45');
            input.value = '';
            imgtag.src = srcInput !== 'undefined' ? srcInput : '#';
            this.setNameFile(nameFile);
        }
    }

    setNameFile(nameFile) {
        this.setState({
            nameFile: nameFile !== 'undefined' ? nameFile : null,
        });
    }

    handleChange(event) {
        const e = event;
        const selectedFile = e.target.files[0];
        const reader = new FileReader();

        const imgtag = document.getElementById('preview-q34e45');
        imgtag.title = selectedFile.name;

        reader.onload = function newImg(dos = event) {
            imgtag.src = dos.target.result;
        };

        reader.readAsDataURL(selectedFile);
        this.setState({
            [event.target.name]: event.target.value,
            nameFile: event.target.files[0].name,
        });
    }

    render() {
        const {
            nameFile,
            iconClass,
            srcInput,
            accept,
        } = this.state;
        const { name } = this.props;
        const classLabel = `input-file-custom ${nameFile != null ? 'on' : ''}${iconClass !== 'undefined' ? iconClass : ''}`;
        return (
            <>
                <label className={classLabel} htmlFor>
                    <input
                        id="input-q34e45"
                        name={name}
                        type="file"
                        className=""
                        onChange={this.handleChange}
                        accept={accept}
                    />
                    <img id="preview-q34e45" alt={nameFile} className="preview" src={srcInput} />
                </label>
                <br />
                <span className={`font-weight-light ${nameFile != null ? 'font-italic' : ''}`}>
                    {nameFile != null ? nameFile : 'Selecciona una imagen..'}
                </span>
            </>
        );
    }
}
InputFileImg.propTypes = {
    name: PropTypes.isRequired,
    nameFile: PropTypes.isRequired,
    srcInput: PropTypes.isRequired,
    iconClass: PropTypes.isRequired,
    reset: PropTypes.isRequired,
};
