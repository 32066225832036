
import React, { Component } from 'react';
import b4ind_logo from '../../assets/img/b4ind/logo_fondo_blanco.png';
import Footer1 from '../../layout/Footer';

class LayoutAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children } = this.props;
        const brand = require ('../../assets/img/b4ind/logo_fondo_blanco.png');
        return (
            <div className="row justify-content-center align-items-center h-100">
                <img alt="logo" src={brand} className="mb-3 institution-logo" />
                <img alt="logo" src={b4ind_logo} className="mb-3 b4ind-logo" />
                <div className="blue" />
                <div className="col-11 col-sm-6 col-md-4 col-lg-3 rounded py-5 bg-white shadow-lg">
                    {children}
                </div>
                <Footer1 />
            </div>
        );
    }
}
export default LayoutAuth;
