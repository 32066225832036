import React from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import { findObject } from '../services/Utils';
import Moment from 'react-moment';
import 'moment-timezone';

import './TableCustom.scss';

const TableCustom = (props) => {
    const {
        trHeader, trBody, data, title, onLoadingTable
    } = props;
    const dataLength = data.length;
    const trHeaderLength = trHeader.length;
    const Title = () => {
        if (title!=null) {
            return (
                <>
                    <h5 className="mt-3">
                        {title}
                    </h5>
                    <hr />
                </>
            )
        }else{
            return null
        }
    }
    const messageEmpty = onLoadingTable === true ? <Loading/> : 'Sin Información';
    return(
        <div className="col-12 px-0" style={{overflow:'auto'}}>
            <Title/>
            <table className="table table-hover table-custom shadow">
                <thead className="bg-white">
                    <tr>
                        {
                            trHeaderLength > 0 ? trHeader.map(((th, key) => {
                                const { content, thClassName } = th;
                                return (
                                    <th key={`tr-head-${key}`} className={`${thClassName}`}>
                                        {content}
                                    </th>
                                );
                            })) : <th className="text-center" colSpan={dataLength}>Sin encabezados</th>
                        }
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {
                        dataLength > 0 ? data.map(((row, i) => {
                            const key = i;
                            return (
                                <tr key={`tr-body-${key}`}>
                                    {
                                        trBody.length > 0 ?  trBody.map(((template, key2) => {
                                            const { content, tdClassName, attrData } = template;
                                            const value = findObject(row, attrData);
                                            const dataContent = (typeof content !== 'undefined' ? content(value,row) : value) ;

                                            let td = null;
                                            
                                            switch(attrData){
                                              case 'created_at':
                                                td = <td key={`td-${key}-${key2}`} className={`${tdClassName}`}> <Moment format="DD-MM-YYYY HH:mm:ss" >{dataContent}</Moment> </td>;
                                                break;
                                              case 'read':
                                                td = <td key={`td-${key}-${key2}`} className={`${tdClassName}-${dataContent}`}> </td>;
                                                break;
                                              case 'description':
                                                td = <td key={`td-${key}-${key2}`} className={`${tdClassName}-${dataContent}`} style={{width: '25rem'}}> {dataContent} </td>;
                                                break;
                                              default:
                                                td = <td key={`td-${key}-${key2}`} className={`${tdClassName}`}> {dataContent} </td>;
                                            }

                                            return(
                                              td
                                            );
                                        }), row) : <td colSpan={trHeaderLength}>{messageEmpty}</td>
                                    }
                                </tr>
                            );
                        }),trBody) : <tr><td className="text-center" colSpan={trHeaderLength}>{messageEmpty}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

TableCustom.propTypes = {
    data: PropTypes.array.isRequired,
    trHeader: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]).isRequired,
        thClassName: PropTypes.string
    })),
    trBody: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
            PropTypes.any,
        ]),
        tdClassName: PropTypes.string.isRequired,
        attrData: PropTypes.string.isRequired
    })),
    title: PropTypes.string,
    onLoadingTable: PropTypes.bool,
};

TableCustom.defaultProps = {
    data: [],
    trHeader: [],
    trBody: {},
    title:null,
    onLoadingTable:false,
};

export default TableCustom;
