import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectPicker } from 'rsuite';

const SelectCustom = (props) => {
    const {
        data, placeholder, className, name,
        size, type
    } = props;

    const [option, setOption] = useState(null);

    const onChangeValue = (item,event) => {
        setOption(item)
    };

    switch (type) {
        case 'rsuite-picker':
            let disabledItemValues=[]
            let defaultValue= (name === 'cc' ? 'PREGRADO - CONTADOR PUBLICO Y AUDITOR' : null);
            defaultValue= (name === 'type_contract' ? 'unsigned' : defaultValue);

            if (option==null && (name === 'type_contract' || name === 'cc') ){
                onChangeValue(defaultValue,null)
            }
            for (const prop in data) {
                if (data[prop].disabled===true) {
                    disabledItemValues.push(data[prop].value)
                }
            }
            return (
                <>
                    <SelectPicker
                        data={data}
                        placeholder={`${placeholder}`}
                        size={`${size}`}
                        className={`w-100 ${className}`}
                        onSelect={(item,event) => ( onChangeValue(item,event) )}
                        onClean={(item,event) => ( onChangeValue(null,event) )}
                        disabledItemValues={disabledItemValues}
                        defaultValue={defaultValue}
                    />
                    <input id={`filtrable-${name}`} name={name} type="hidden" value={option}></input>
                </>
            );

        case 'simple':
            return (
                <select
                    className={`custom-select  custom-select-sm ${className}`}
                    name={name}
                >
                    <option value="">{placeholder}</option>
                    {
                        data.length > 0 ? data.map(((t, i) => {
                            const label = t.label;
                            const value = t.value;
                            if (t.disabled) {
                                return (
                                    <option key={`select-${name}-${i}`} value={value} disabled={true}>
                                        {label}
                                    </option>
                                );
                            }
                            if (value=='PREGRADO - CONTADOR PUBLICO Y AUDITOR') {
                                 return (
                                    <option key={`select-${name}-${i}`} value={value} selected>
                                        {label}
                                    </option>
                                );
                            }
                            return (
                                <option key={`select-${name}-${i}`} value={value}>
                                    {label}
                                </option>
                            );
                        }), this) : null
                    }

                </select>
            );
        default:
            return (
                <strong className="d-block px-3">Define el tipo de select</strong>
            )
    }
   
}

SelectCustom.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })),
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
};

SelectCustom.defaultProps = {
    data: [],
    placeholder: 'Elije una opcion',
    name: 'input',
    size: 'md-b',
    type: ''
};

export default SelectCustom;
