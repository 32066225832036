import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const Loading = (props) => {
    const {
        loading, message, children
    } = props;
    if (loading === true) {
        return(
            <div className="text-center col py-5">
                <Spinner />
                <h5>{message}</h5>
            </div>
        )
    }
    return(<div className="row">{children}</div>)
}

Loading.propTypes = {
    loading:PropTypes.bool.isRequired,
    message:PropTypes.string.isRequired,
    children:PropTypes.any,
};

Loading.defaultProps = {
    message: 'Un momento por favor...',
    loading: true,
    children:{},
};

export default Loading;