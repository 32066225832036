import React from 'react';
import {
    BrowserRouter as Router, Route, Switch, /* Redirect, */
} from 'react-router-dom';
// import PropTypes from 'prop-types';
import SignIn from './pages/Login/SignIn';

const AppRouterAuth = () => (
    <Router>
        <Switch>
            <Route exact path="/login" component={SignIn} />
            {/* <Redirect exact from='/' to='/login' /> */}
            {/* Si no existe la ruta. debises ser vista 404 */}
            <Route component={SignIn} />
        </Switch>
    </Router>
);
export default AppRouterAuth;