import React from 'react';
import {
    Card, Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { generateKey } from '../../services/Utils';
import {
    StatusBlue, StatusGreen, StatusYellow, CardS, CardB, Title, Number,
} from '../../styles/General';

class CardService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userservice: props.userservice,
            statusP: 'Proyectada',

        };
    }

    componentDidMount() {
        const { userservice } = this.state;
        if (!userservice) {
            this.setState({
                userservice: {
                    id: 3,
                    name: 'Ayudantia de Programacion',
                    description: 'Servicios educacional clases de Ingenieria Civil para primer semestre  facultada de Ingenieria Universidad Alberto Hurtado',
                    contract: true,
                    status: 'Activo',
                    bills: [
                        {
                            id: 1, status: 'Pagada', gross_amount: 100000, date: '17/02/2020',
                        },
                        {
                            id: 4, status: 'Pendiente', gross_amount: 300000, date: '17/02/2020',
                        },
                        {
                            id: 2, status: 'Pendiente', gross_amount: 200000, date: '17/02/2020',
                        },
                        {
                            id: 3, status: 'Proyectada', gross_amount: 100000, date: '17/02/2020',
                        },
                    ],

                },
            });
        }
    }


    getColor(status) {
        const { statusP } = this.state;
        if (status === 'Pagada') {
            return (<StatusGreen key={generateKey(status)}><p className="text-light">{status}</p></StatusGreen>);
        } if (status === 'Pendiente') {
            return (<StatusYellow key={generateKey(status)}><p className="text-light">{status}</p></StatusYellow>);
        }
        return (<StatusBlue key={generateKey(status)}><p className="text-light">{statusP}</p></StatusBlue>);
    }


    render() {
        const { userservice } = this.state;
        return (
            <div>
                <CardS>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Title>Nombre del Servicio</Title>
                                <div>{userservice.name}</div>

                            </Col>
                            <Col>
                                <Title>Descripcion del servicio</Title>
                                <div>{userservice.description}</div>
                            </Col>
                            <Col>
                                <Title>Contrato</Title>
                                <div>{userservice.contract ? 'Si' : 'No'}</div>

                            </Col>
                            <Col>
                                <Title>Estado</Title>
                                <StatusGreen><p className="text-light">{userservice.status}</p></StatusGreen>
                                <Title>Total Actual</Title>
                                <Number>
                                    {userservice.bills.reduce(
                                        (acc, val) => acc + val.gross_amount, 0,
                                    )}
                                </Number>

                            </Col>
                        </Row>
                    </Card.Body>
                </CardS>
                <Row>
                    <Col>
                        <CardB>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Title>#Boletas</Title>
                                        {userservice.bills.map((bill) => <p key={`${bill.id}-bill-number`}>{bill.id}</p>)}

                                    </Col>
                                    <Col>
                                        <Title>Estado</Title>
                                        {userservice.bills.map((bill) => (
                                            this.getColor(bill.status)
                                        ))}
                                    </Col>
                                    <Col>
                                        <Title>fecha de pago</Title>
                                        {userservice.bills.map((bill) => <p key={`${bill.id}-bill-date`}>{bill.date}</p>)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </CardB>
                    </Col>
                    <Col>
                        <CardB>
                            <Card.Body>
                                <Title>Sub totales de pago</Title>
                                <Row>

                                    <Col>

                                        <StatusGreen>
                                            <p className="text-light text-center">Pagada</p>
                                        </StatusGreen>
                                        <StatusYellow>
                                            <p className="text-light text-center">Pendiente</p>
                                        </StatusYellow>
                                        <StatusBlue>
                                            <p className="text-light text-center">Proyectada</p>
                                        </StatusBlue>

                                    </Col>
                                    <Col>
                                        <p>
                                            {userservice.bills.filter(({ status }) => status === 'Pagada').reduce(
                                                (acc, val) => acc + val.gross_amount, 0,
                                            )}
                                        </p>
                                        <p>
                                            {userservice.bills.filter(({ status }) => status === 'Pendiente').reduce(
                                                (acc, val) => acc + val.gross_amount, 0,
                                            )}
                                        </p>
                                        <p>
                                            {userservice.bills.filter(({ status }) => status === 'Proyectada').reduce(
                                                (acc, val) => acc + val.gross_amount, 0,
                                            )}
                                        </p>


                                    </Col>
                                    <Col>
                                        <Title>Valor Bruto Contrato</Title>
                                        <Number>
                                            {userservice.bills.reduce(
                                                (acc, val) => acc + val.gross_amount, 0,
                                            )}
                                        </Number>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </CardB>
                    </Col>
                </Row>
            </div>

        );
    }
}

CardService.propTypes = {
    userservice: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        contract: PropTypes.bool.isRequired,
        bills: PropTypes.array.isRequired,
    }).isRequired,

};

export default CardService;
