import React from 'react';
import {
    Card, Col, Row, Carousel,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import userPhoto from '../../assets/icon/icon-login.png';
import {
    Name, Status, CardS, UserImg, Subtitle, Number,
} from '../../styles/Users';


class CardUserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userdata: props.userdata,
            userInfo: [],
            nextIcon: '',
            prevIcon: '',
        };
        this.loadInfoMes = this.loadInfoMes.bind(this);
    }

    componentDidMount() {
        this.loadInfoMes();
    }

    loadInfoMes() {
        const { userdata } = this.state;
        const info = [{
            userId: userdata.id, month: 'Enero', services: 2, mount: 700000, year: '2020',
        }, {
            userId: userdata.id, month: 'Febrero', services: 3, mount: 800000, year: '2020',
        }, {
            userId: userdata.id, month: 'Marzo', services: 2, mount: 700000, year: '2020',
        }];
        this.setState({ userInfo: info });
    }


    render() {
        const {
            userdata, userInfo, nextIcon, prevIcon,
        } = this.state;
        return (
            <CardS>
                <Card.Body>
                    <Row>
                        <Col md={1} xs>
                            <UserImg
                                width={64}
                                height={64}
                                className="center"
                                src={userPhoto}
                                roundedCircle
                            />
                            <Status>
                                <p className="text-light text-center">{userdata.status}</p>
                            </Status>

                        </Col>
                        <Col md={3}>
                            <Name>{userdata.name}</Name>
                            <div>{userdata.rol}</div>
                            <div>{userdata.email}</div>
                            <div>{userdata.phone}</div>
                        </Col>
                        <Col>
                            <Carousel nextIcon={nextIcon} prevIcon={prevIcon} indicators={false}>
                                {userInfo.map((info) => (
                                    <Carousel.Item key={info.userId + info.month}>
                                        <Subtitle>{`${info.month} ${info.year}`}</Subtitle>
                                        <Row>
                                            <Col>
                                                <Subtitle>Servicios Activos</Subtitle>
                                                <Number>{info.services}</Number>

                                            </Col>
                                            <Col>
                                                <Subtitle>Total Mes</Subtitle>
                                                <Number>
                                                    {info.mount}
                                                </Number>
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                ))}

                            </Carousel>
                        </Col>
                    </Row>
                </Card.Body>
            </CardS>
        );
    }
}

CardUserInfo.propTypes = {
    userdata: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        rol: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
    }).isRequired,
};

export default CardUserInfo;
