import { InstitutionsIndex } from '../../../services/Api';
import useSWR, { mutate } from 'swr';


const useInstitutions = () => {

    const getInstitutionsIndex = (holding_id=0) => {
        const params = {
            holding_id: holding_id,
        };
        console.log("params",params);
        const { data, error, isLoading,mutate } = useSWR(
            'superadmin/institution/list', 
            (url) => InstitutionsIndex(url,params)
        ); 

        return {
            institutions: data || [],
            isLoadingInstitutions: isLoading,
            isErrorInstitutions: error,
            mutateInstitutions : mutate,
        };

    };
    
    return {
        getInstitutionsIndex
    };
      
};

export default useInstitutions;
