import React from 'react';
import PropTypes from 'prop-types';

const CardStats = (props) => {
    const {
        data, size, className,
    } = props;
    const dataLength = data.length;
    return (
        <div className={`${className}`}>
            <div className="row justify-content-center">
                {
                    dataLength > 0 ? data.map(((t, i) => {
                        const { title, stast } = t;
                        const line = dataLength - 1 === i ? '' : 'line-right';
                        const classSize = (size !== 'auto' ?  `col-${size}` : 'col');
                        return (
                            <div key={`crad-${i}`} className={`${classSize} py-3 text-center ${line}`}>
                                <strong>{title}</strong>
                                <h1 className="text-orange font-weight-bold mb-0">
                                    {stast}
                                </h1>
                            </div>
                        );
                    }), size, dataLength) : null
                }
            </div>
        </div>
    );
};

CardStats.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
    })),
    size: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
};

CardStats.defaultProps = {
    data: [],
    size: 3,
    className: 'col-12 bg-white mb-3 shadow-sm rounded',
};

export default CardStats;
