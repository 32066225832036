/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ListNotifications, MarkAsRead } from '../../services/Api';
import FormProcessing from '../../services/FormProcessing';
import {
    SelectCustom, CardStats, TableCustom,
    PaginateCustom, Loading, CardFormFiltre
} from '../../ComponentsHtml/AllHtml';

export default class ListN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            centerCost: [],
            onLoading: true,
            onLoadingTable: false,
            pagination: null,
        };
        this.listTemplate = this.listTemplate.bind(this);
        this.listFilter = this.listFilter.bind(this);
        this.leidas = this.leidas.bind(this);
        this.formSearch = React.createRef();
    }

    async componentDidMount() {
        this.listTemplate();
    }

    async listTemplate(page = {}) {
        this.setState({ onLoadingTable: true, notifications:[] });
        const notifications = await ListNotifications(page);
        this.setState({
            pagination: {
                last_page: notifications.last_page,
                current_page: notifications.current_page,
                per_page: notifications.per_page,
            },
            onLoading: false,
            notifications: notifications.data,
            onLoadingTable: false
        });
    }

    async listFilter(e) {
        e.preventDefault();
        this.setState({ onLoadingTable: true, notifications:[] });

        const formSearch = await FormProcessing.toObject(this.formSearch.current);
        const urlParams = new URLSearchParams(window.location.search);
        Object.entries(formSearch).map((data) => {
            urlParams.set(data[0], data[1]);
        });
        urlParams.delete('page');
        this.props.history.push(`${window.location.pathname}?${urlParams.toString()}`);
        this.listTemplate();
    }

    async leidas(e) {
        e.preventDefault();
        const leidas = await MarkAsRead();
    }

    render() {
        const {
            notifications, onLoading, pagination, onLoadingTable,
        } = this.state;
        return (
            <Loading message="Cargando servicios.." loading={onLoading}>
                <button className="btn btn-success" type="button" onClick={ (e) => { this.leidas(e) } }>
                    Marcar como leidas
                </button>
                <TableCustom
                    title="Todas las notificaciones"
                    data={notifications}
                    onLoadingTable={onLoadingTable}
                    trHeader={[
                        {content:'#'},{content:'Leido'},{content:'Enviado por'}, {content:'Email'},
                        {content:'Descripcion'}, {content:'Recibida el'}
                    ]}
                    trBody={[
                        {tdClassName:"small", attrData:"id"},
                        {tdClassName:"small read", attrData:"read"},
                        {tdClassName:"small", attrData:"sender.full_name"},
                        {tdClassName:"small", attrData:"sender.email"},
                        {tdClassName:"small", attrData:"short_description"},
                        {tdClassName:"small", attrData:"created_at"},
                    ]}
                />
                {
                    pagination != null ? (
                        <PaginateCustom
                            last_page={pagination.last_page}
                            current_page={pagination.current_page}
                            per_page={pagination.per_page}
                            loadList={this.listTemplate}
                        />
                    ) : null
                }
            </Loading>
        );
    }
}
