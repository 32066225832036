import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class PaginateCustom extends Component {
    constructor(props) {
        super(props);
        const pages = [];
        const last = props.last_page;
        const current = props.current_page;
        const hasta = current + 3 <= last ? current + 3 : last;
        const desde = current - 3 >= 1 ? current - 3 : 1;
        for (let i = desde; i <= hasta; i++) {
            pages[i] = { page: i };
        }
        this.state = {
            last_page:props.last_page,
            current_page:props.current_page,
            per_page:props.per_page,
            pages,
        };
        this.getResuls = this.getResuls.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const pages=[];
        const last = props.last_page
        const current = props.current_page
        const hasta = current + 3 <= last ? current + 3 : last;
        const desde = current - 3 >= 1 ? current - 3 : 1;
        for (let i = desde; i <= hasta; i++) {
            pages[i] = { page: i };
        }
        this.setState({
            last_page:props.last_page,
            current_page:props.current_page,
            per_page:props.per_page,
            pages
        });
    }

    async getResuls(e, page) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', page.page)
        // console.log(urlParams.toString())
        this.props.history.push(window.location.pathname+'?'+urlParams.toString());
        e.preventDefault();
        await this.props.loadList(page);
    }

    render() {
        let nex = {};
        const { last_page } = this.state
        return (
            <Pagination aria-label="Page navigation example" className={this.props.className} listClassName="mb-0 pagination-out-bulma">
                <PaginationItem>
                    <PaginationLink onClick={(e) => this.getResuls(e, { page: 1 })}>
                        {'<<'}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        previous
                        onClick={(e) => this.getResuls(e, (
                            this.state.current_page - 1 < 1 ? { page: null } : { page: this.state.current_page - 1 }
                        ))}
                    />
                </PaginationItem>
                { typeof this.state.pages === 'object' ? this.state.pages.map((p, i) => {
                    if (i===this.state.current_page) {
                        nex=(1+i>this.state.last_page ? {page:i} :{page:1+i});
                        return(
                            <PaginationItem key={i} active>
                                <PaginationLink onClick={(e) => this.getResuls(e, p)}>
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    }
                    return(
                        <PaginationItem key={i}>
                            <PaginationLink onClick={(e) => this.getResuls(e, p)}>
                                {i}
                            </PaginationLink>
                        </PaginationItem>
                    );
                }) : null}
                <PaginationItem>
                    <PaginationLink next onClick={(e) => this.getResuls(e,nex) }/>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink onClick={(e) => this.getResuls(e, { page: last_page })}>
                        {'>>'}
                    </PaginationLink>
                </PaginationItem>

            </Pagination>
        );
    }
}
export default withRouter(PaginateCustom);